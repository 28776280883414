import { graphql } from "gatsby";
import { CoreParagraphBlockFragment } from "../components/blocks/CoreParagraphBlock/"; // eslint-disable-line
import { CoreHeadingBlockFragment } from "../components/blocks/CoreHeadingBlock"; // eslint-disable-line
import { CoreImageBlockFragment } from "../components/blocks/CoreImageBlock"; // eslint-disable-line
import { CoreColumnBlockFragment } from "../components/blocks/CoreColumnBlock"; // eslint-disable-line
import { CoreColumnsBlockFragment } from "../components/blocks/CoreColumnsBlock"; // eslint-disable-line
import { CoreButtonsBlockFragment } from "../components/blocks/CoreButtonsBlock"; // eslint-disable-line
import { CoreButtonBlockFragment } from "../components/blocks/CoreButtonBlock"; // eslint-disable-line
import { CoreGalleryBlockFragment } from "../components/blocks/CoreGalleryBlock"; // eslint-disable-line
import { CoreQuoteBlockFragment } from "../components/blocks/CoreQuoteBlock"; // eslint-disable-line
import { CoreListBlockFragment } from "../components/blocks/CoreListBlock"; // eslint-disable-line
import { CoreListItemBlockFragment } from "../components/blocks/CoreListItemBlock"; // eslint-disable-line
import { CoreTableBlockFragment } from "../components/blocks/CoreTableBlock"; // eslint-disable-line
import { CoreFileBlockFragment } from "../components/blocks/CoreFileBlock"; // eslint-disable-line
import { CoreSpacerBlockFragment } from "../components/blocks/CoreSpacerBlock"; // eslint-disable-line
import { GravityformsFormBlockFragment } from "../components/blocks/GravityformsFormBlock"; // eslint-disable-line

import { CgbAccordionBlockFragment } from "../components/blocks/CgbAccordionBlock"; // eslint-disable-line
import { CgbAccordionInnerBlockFragment } from "../components/blocks/CgbAccordionInnerBlock"; // eslint-disable-line
import { CgbBackgroundWrapBlockFragment } from "../components/blocks/CgbBackgroundWrapBlock"; // eslint-disable-line
import { CgbBannerCalloutCtaBlockFragment } from "../components/blocks/CgbBannerCalloutCtaBlock"; // eslint-disable-line
import { CgbBannerMessageBlockFragment } from "../components/blocks/CgbBannerMessageBlock"; // eslint-disable-line
import { CgbBlogBannerBlockFragment } from "../components/blocks/CgbBlogBannerBlock"; // eslint-disable-line
import { CgbBlogPagePostsBlockFragment } from "../components/blocks/CgbBlogPagePostsBlock"; // eslint-disable-line
import { CgbBlogPostWrapBlockFragment } from "../components/blocks/CgbBlogPostWrapBlock"; // eslint-disable-line
import { CgbBrandLinkBlockFragment } from "../components/blocks/CgbBrandLinkBlock"; // eslint-disable-line
import { CgbBrandLinkGridBlockFragment } from "../components/blocks/CgbBrandLinkGridBlock"; // eslint-disable-line
import { CgbBrandLinkRowBlockFragment } from "../components/blocks/CgbBrandLinkRowBlock"; // eslint-disable-line
import { CgbBreakoutGalleryIntroBlockFragment } from "../components/blocks/CgbBreakoutGalleryIntroBlock"; // eslint-disable-line
import { CgbBreakoutIntroBlockFragment } from "../components/blocks/CgbBreakoutIntroBlock"; // eslint-disable-line
import { CgbCalloutCtaBlockFragment } from "../components/blocks/CgbCalloutCtaBlock"; // eslint-disable-line
import { CgbCardBlockFragment } from "../components/blocks/CgbCardBlock"; // eslint-disable-line
import { CgbCardRowBlockFragment } from "../components/blocks/CgbCardBlock"; // eslint-disable-line
import { CgbContactBannerBlockFragment } from "../components/blocks/CgbContactBannerBlock"; //eslint-disable-line
import { CgbContactCalloutBlockFragment } from "../components/blocks/CgbContactCalloutBlock"; // eslint-disable-line
import { CgbContactColumnsBlockFragment } from "../components/blocks/CgbContactColumnsBlock"; // eslint-disable-line
import { CgbFactStackBlockFragment } from "../components/blocks/CgbFactStackBlock"; // eslint-disable-line
import { CgbFactStackInnerBlockFragment } from "../components/blocks/CgbFactStackInnerBlock"; // eslint-disable-line
import { CgbFeaturedIconsBlockFragment } from "../components/blocks/CgbFeaturedIconsBlock"; // eslint-disable-line
import { CgbFeaturedIconsInnerBlockFragment } from "../components/blocks/CgbFeaturedIconsInnerBlock"; // eslint-disable-line
import { CgbFileListBlockFragment } from "../components/blocks/CgbFileListBlock"; // eslint-disable-line
import { CgbFileListInnerBlockFragment } from "../components/blocks/CgbFileListInnerBlock"; // eslint-disable-line
import { CgbFileListInnerSmallBlockFragment } from "../components/blocks/CgbFileListInnerSmallBlock"; // eslint-disable-line
import { CgbGoodToGoBlockFragment } from "../components/blocks/CgbGoodToGoBlock"; // eslint-disable-line
import { CgbIconListIntroBlockFragment } from "../components/blocks/CgbIconListIntroBlock"; // eslint-disable-line
import { CgbIconListInnerBlockFragment } from "../components/blocks/CgbIconListInnerBlock"; // eslint-disable-line
import { CgbIconListBlockFragment } from "../components/blocks/CgbIconListBlock"; // eslint-disable-line
import { CgbIconRowBlockFragment } from "../components/blocks/CgbIconRowBlock"; //eslint-disable-line
import { CgbIconRowInnerBlockFragment } from "../components/blocks/CgbIconRowInnerBlock"; //eslint-disable-line
import { CgbImageBreakdownBlockFragment } from "../components/blocks/CgbImageBreakdownBlock"; // eslint-disable-line
import { CgbImageBreakdownInnerBlockFragment } from "../components/blocks/CgbImageBreakdownInnerBlock"; // eslint-disable-line
import { CgbImageContentRowsBlockFragment } from "../components/blocks/CgbImageContentRowsBlock"; // eslint-disable-line
import { CgbImageContentRowsInnerBlockFragment } from "../components/blocks/CgbImageContentRowsInnerBlock"; // eslint-disable-line
import { CgbImageGalleryGridBlockFragment } from "../components/blocks/CgbImageGalleryGridBlock"; // eslint-disable-line
import { CgbImageGalleryGridInnerLargeBlockFragment } from "../components/blocks/CgbImageGalleryGridInnerLargeBlock"; // eslint-disable-line
import { CgbImageGalleryGridInnerSmallBlockFragment } from "../components/blocks/CgbImageGalleryGridInnerSmallBlock"; // eslint-disable-line
import { CgbImageLinkSliderBlockFragment } from "../components/blocks/CgbImageLinkSliderBlock"; // eslint-disable-line
import { CgbImageLinkRowBlockFragment } from "../components/blocks/CgbImageLinkRowBlock"; //eslint-disable-line
import { CgbImageLinkBlockFragment } from "../components/blocks/CgbImageLinkBlock"; // eslint-disable-line
import { CgbImageSliderBlockFragment } from "../components/blocks/CgbImageSliderBlock"; // eslint-disable-line
import { CgbIntroGalleryBlockFragment } from "../components/blocks/CgbIntroGalleryBlock"; // eslint-disable-line
import { CgbIntroGallerySplitBlockFragment } from "../components/blocks/CgbIntroGallerySplitBlock"; // eslint-disable-line
import { CgbIntroImageSliderBlockFragment } from "../components/blocks/CgbIntroImageSliderBlock"; // eslint-disable-line
import { CgbLandingBannerBlockFragment } from "../components/blocks/CgbLandingBannerBlock"; // eslint-disable-line
import { CgbLandingBannerVideoBlockFragment } from "../components/blocks/CgbLandingBannerVideoBlock"; // eslint-disable-line
import { CgbLatestPostsBlockFragment } from "../components/blocks/CgbLatestPostsBlock"; // eslint-disable-line
import { CgbLogoSliderBlockFragment } from "../components/blocks/CgbLogoSliderBlock"; // eslint-disable-line
import { CgbNextPreviousPostsBlockFragment } from "../components/blocks/CgbNextPreviousPostsBlock"; // eslint-disable-line
import { CgbOfferGridBlockFragment } from "../components/blocks/CgbOfferGridBlock"; // eslint-disable-line
import { CgbOfferGridInnerBlockFragment } from "../components/blocks/CgbOfferGridInnerBlock"; // eslint-disable-line
import { CgbPageTitleBlockFragment } from "../components/blocks/CgbPageTitleBlock"; // eslint-disable-line
import { CgbPopularProductsBlockFragment } from "../components/blocks/CgbPopularProductsBlock"; // eslint-disable-line
import { CgbProductsByTagBlockFragment } from "../components/blocks/CgbProductsByTagBlock"; // eslint-disable-line
import { CgbPullquoteBlockFragment } from "../components/blocks/CgbPullquoteBlock"; // eslint-disable-line
import { CgbSeparatorBlockFragment } from "../components/blocks/CgbSeparatorBlock"; // eslint-disable-line
import { CgbSocialSharingBlockFragment } from "../components/blocks/CgbSocialSharingBlock"; // eslint-disable-line
import { AcfSpecifiersProductsBlockFragment } from "../components/blocks/AcfSpecifiersProductsBlock"; // eslint-disable-line
import { CgbTabContentBlockFragment } from "../components/blocks/CgbTabContentBlock"; // eslint-disable-line
import { CgbTabContentInnerBlockFragment } from "../components/blocks/CgbTabContentInnerBlock"; // eslint-disable-line
import { CgbTeamGridBlockFragment } from "../components/blocks/CgbTeamGridBlock"; // eslint-disable-line
import { CgbTeamGridInnerBlockFragment } from "../components/blocks/CgbTeamGridInnerBlock"; // eslint-disable-line
import { CgbTestimonialBlockFragment } from "../components/blocks/CgbTestimonialBlock"; // eslint-disable-line
import { CgbTimelineBlockFragment } from "../components/blocks/CgbTimelineBlock"; //eslint-disable-line
import { CgbTimelineInnerBlockFragment } from "../components/blocks/CgbTimelineInnerBlock"; //eslint-disable-line
import { CgbThickIntroBlockFragment } from "../components/blocks/CgbThickIntroBlock"; // eslint-disable-line
import { CgbThinIntroBlockFragment } from "../components/blocks/CgbThinIntroBlock"; // eslint-disable-line
import { CgbTwoCardIntroBlockFragment } from "../components/blocks/CgbTwoCardIntroBlock"; // eslint-disable-line
import { CgbTwoColInnerBlockFragment } from "../components/blocks/CgbTwoColInnerBlock"; // eslint-disable-line
import { cgbTwoColWrapperBlockFragment } from "../components/blocks/CgbTwoColWrapperBlock"; // eslint-disable-line
import { CgbVideoBannerMessageBlockFragment } from "../components/blocks/CgbVideoBannerMessageBlock"; // eslint-disable-line
import { CgbVideoEmbedBlockFragment } from "../components/blocks/CgbVideoEmbedBlock"; // eslint-disable-line
import { CgbVideoTestimonialBlockFragment } from "../components/blocks/CgbVideoTestimonialBlock"; // eslint-disable-line
import { AcfBrewGalleryBlockFragment } from "../components/blocks/AcfBrewGalleryBlock"; // eslint-disable-line
import { AcfNbsIframeBlockFragment } from "../components/blocks/AcfNbsIframeBlock"; // eslint-disable-line

import { CoreParagraphBlockPreview } from "../components/blocks/CoreParagraphBlock/"; // eslint-disable-line
import { CoreHeadingBlockPreview } from "../components/blocks/CoreHeadingBlock/"; // eslint-disable-line
import { CoreColumnBlockPreview } from "../components/blocks/CoreColumnBlock/"; // eslint-disable-line
import { CoreColumnsBlockPreview } from "../components/blocks/CoreColumnsBlock/"; // eslint-disable-line
import { CoreImageBlockPreview } from "../components/blocks/CoreImageBlock/"; // eslint-disable-line
import { CoreButtonsBlockPreview } from "../components/blocks/CoreButtonsBlock/"; // eslint-disable-line
import { CoreButtonBlockPreview } from "../components/blocks/CoreButtonBlock/"; // eslint-disable-line
import { CoreGalleryBlockPreview } from "../components/blocks/CoreGalleryBlock/"; // eslint-disable-line
import { CoreQuoteBlockPreview } from "../components/blocks/CoreQuoteBlock/"; // eslint-disable-line
import { CoreListBlockPreview } from "../components/blocks/CoreListBlock/"; // eslint-disable-lined
import { CoreListItemBlockPreview } from "../components/blocks/CoreListItemBlock/"; // eslint-disable-lined
import { CoreTableBlockPreview } from "../components/blocks/CoreTableBlock/"; // eslint-disable-lined
import { CoreFileBlockPreview } from "../components/blocks/CoreFileBlock/"; // eslint-disable-lined
import { CoreSpacerBlockPreview } from "../components/blocks/CoreSpacerBlock/"; // eslint-disable-lined
import { GravityformsFormBlockPreview } from "../components/blocks/GravityformsFormBlock"; // eslint-disable-line
import { CgbAccordionBlockPreview } from "../components/blocks/CgbAccordionBlock"; // eslint-disable-line
import { CgbAccordionInnerBlockPreview } from "../components/blocks/CgbAccordionInnerBlock"; // eslint-disable-line
import { CgbBackgroundWrapBlockPreview } from "../components/blocks/CgbBackgroundWrapBlock"; // eslint-disable-line
import { CgbBannerCalloutCtaBlockPreview } from "../components/blocks/CgbBannerCalloutCtaBlock"; // eslint-disable-line
import { CgbBannerMessageBlockPreview } from "../components/blocks/CgbBannerMessageBlock"; // eslint-disable-line
import { CgbBlogBannerBlockPreview } from "../components/blocks/CgbBlogBannerBlock"; // eslint-disable-line
import { CgbBlogPagePostsBlockPreview } from "../components/blocks/CgbBlogPagePostsBlock"; // eslint-disable-line
import { CgbBlogPostWrapBlockPreview } from "../components/blocks/CgbBlogPostWrapBlock"; // eslint-disable-line
import { CgbBrandLinkBlockPreview } from "../components/blocks/CgbBrandLinkBlock"; // eslint-disable-line
import { CgbBrandLinkGridBlockPreview } from "../components/blocks/CgbBrandLinkGridBlock"; // eslint-disable-line
import { CgbBrandLinkRowBlockPreview } from "../components/blocks/CgbBrandLinkRowBlock"; // eslint-disable-line
import { CgbBreakoutGalleryIntroBlockPreview } from "../components/blocks/CgbBreakoutGalleryIntroBlock"; // eslint-disable-line
import { CgbBreakoutIntroBlockPreview } from "../components/blocks/CgbBreakoutIntroBlock"; // eslint-disable-line
import { CgbCalloutCtaBlockPreview } from "../components/blocks/CgbCalloutCtaBlock"; // eslint-disable-line
import { CgbCardBlockPreview } from "../components/blocks/CgbCardBlock"; // eslint-disable-line
import { CgbCardRowBlockPreview } from "../components/blocks/CgbCardRowBlock"; // eslint-disable-line
import { CgbContactBannerBlockPreview } from "../components/blocks/CgbContactBannerBlock"; // eslint-disable-line
import { CgbContactCalloutBlockPreview } from "../components/blocks/CgbContactCalloutBlock"; // eslint-disable-line
import { CgbContactColumnsBlockPreview } from "../components/blocks/CgbContactColumnsBlock"; // eslint-disable-line
import { CgbFactStackBlockPreview } from "../components/blocks/CgbFactStackBlock"; // eslint-disable-line
import { CgbFactStackInnerBlockPreview } from "../components/blocks/CgbFactStackInnerBlock"; // eslint-disable-line
import { CgbFeaturedIconsBlockPreview } from "../components/blocks/CgbFeaturedIconsBlock"; // eslint-disable-line
import { CgbFeaturedIconsInnerBlockPreview } from "../components/blocks/CgbFeaturedIconsInnerBlock"; // eslint-disable-line
import { CgbFileListBlockPreview } from "../components/blocks/CgbFileListBlock"; // eslint-disable-line
import { CgbFileListInnerBlockPreview } from "../components/blocks/CgbFileListInnerBlock"; // eslint-disable-line
import { CgbFileListInnerSmallBlockPreview } from "../components/blocks/CgbFileListInnerSmallBlock"; // eslint-disable-line
import { CgbGoodToGoBlockPreview } from "../components/blocks/CgbGoodToGoBlock"; // eslint-disable-line
import { CgbIconListIntroBlockPreview } from "../components/blocks/CgbIconListIntroBlock"; // eslint-disable-line
import { CgbIconListInnerBlockPreview } from "../components/blocks/CgbIconListInnerBlock"; // eslint-disable-line
import { CgbIconListBlockPreview } from "../components/blocks/CgbIconListBlock"; // eslint-disable-line
import { CgbIconRowBlockPreview } from "../components/blocks/CgbIconRowBlock"; //eslint-disable-line
import { CgbIconRowInnerBlockPreview } from "../components/blocks/CgbIconRowInnerBlock"; //eslint-disable-line
import { CgbImageBreakdownBlockPreview } from "../components/blocks/CgbImageBreakdownBlock"; // eslint-disable-line
import { CgbImageBreakdownInnerBlockPreview } from "../components/blocks/CgbImageBreakdownInnerBlock"; // eslint-disable-line
import { CgbImageContentRowsBlockPreview } from "../components/blocks/CgbImageContentRowsBlock"; // eslint-disable-line
import { CgbImageContentRowsInnerBlockPreview } from "../components/blocks/CgbImageContentRowsInnerBlock"; // eslint-disable-line
import { CgbImageGalleryGridBlockPreview } from "../components/blocks/CgbImageGalleryGridBlock"; // eslint-disable-line
import { CgbImageGalleryGridInnerLargeBlockPreview } from "../components/blocks/CgbImageGalleryGridInnerLargeBlock"; // eslint-disable-line
import { CgbImageGalleryGridInnerSmallBlockPreview } from "../components/blocks/CgbImageGalleryGridInnerSmallBlock"; // eslint-disable-line
import { CgbImageLinkSliderBlockPreview } from "../components/blocks/CgbImageLinkSliderBlock"; // eslint-disable-line
import { CgbImageLinkRowBlockPreview } from "../components/blocks/CgbImageLinkRowBlock"; //eslint-disable-line
import { CgbImageLinkBlockPreview } from "../components/blocks/CgbImageLinkBlock"; // eslint-disable-line
import { CgbImageSliderBlockPreview } from "../components/blocks/CgbImageSliderBlock"; // eslint-disable-line
import { CgbIntroGalleryBlockPreview } from "../components/blocks/CgbIntroGalleryBlock"; // eslint-disable-line
import { CgbIntroGallerySplitBlockPreview } from "../components/blocks/CgbIntroGallerySplitBlock"; // eslint-disable-line
import { CgbIntroImageSliderBlockPreview } from "../components/blocks/CgbIntroImageSliderBlock"; // eslint-disable-line
import { CgbLandingBannerBlockPreview } from "../components/blocks/CgbLandingBannerBlock"; // eslint-disable-line
import { CgbLandingBannerVideoBlockPreview } from "../components/blocks/CgbLandingBannerVideoBlock"; // eslint-disable-line
import { CgbLatestPostsBlockPreview } from "../components/blocks/CgbLatestPostsBlock"; // eslint-disable-line
import { CgbLogoSliderBlockPreview } from "../components/blocks/CgbLogoSliderBlock"; // eslint-disable-line
import { CgbNextPreviousPostsBlockPreview } from "../components/blocks/CgbNextPreviousPostsBlock"; // eslint-disable-line
import { CgbOfferGridBlockPreview } from "../components/blocks/CgbOfferGridBlock"; // eslint-disable-line
import { CgbOfferGridInnerBlockPreview } from "../components/blocks/CgbOfferGridInnerBlock"; // eslint-disable-line
import { CgbPageTitleBlockPreview } from "../components/blocks/CgbPageTitleBlock"; // eslint-disable-line
import { CgbPopularProductsBlockPreview } from "../components/blocks/CgbPopularProductsBlock"; // eslint-disable-line
import { CgbProductsByTagBlockPreview } from "../components/blocks/CgbProductsByTagBlock"; // eslint-disable-line
import { CgbPullquoteBlockPreview } from "../components/blocks/CgbPullquoteBlock"; // eslint-disable-line
import { CgbSeparatorBlockPreview } from "../components/blocks/CgbSeparatorBlock"; // eslint-disable-line
import { CgbSocialSharingBlockPreview } from "../components/blocks/CgbSocialSharingBlock"; // eslint-disable-line
import { AcfSpecifiersProductsBlockPreview } from "../components/blocks/AcfSpecifiersProductsBlock"; // eslint-disable-line
import { CgbTabContentBlockPreview } from "../components/blocks/CgbTabContentBlock"; // eslint-disable-line
import { CgbTabContentInnerBlockPreview } from "../components/blocks/CgbTabContentInnerBlock"; // eslint-disable-line
import { CgbTeamGridBlockPreview } from "../components/blocks/CgbTeamGridBlock"; // eslint-disable-line
import { CgbTeamGridInnerBlockPreview } from "../components/blocks/CgbTeamGridInnerBlock"; // eslint-disable-line
import { CgbTestimonialBlockPreview } from "../components/blocks/CgbTestimonialBlock"; // eslint-disable-line
import { CgbTimelineBlockPreview } from "../components/blocks/CgbTimelineBlock"; // eslint-disable-line
import { CgbTimelineInnerBlockPreview } from "../components/blocks/CgbTimelineInnerBlock"; // eslint-disable-line
import { CgbThickIntroBlockPreview } from "../components/blocks/CgbThickIntroBlock"; // eslint-disable-line
import { CgbThinIntroBlockPreview } from "../components/blocks/CgbThinIntroBlock"; // eslint-disable-line
import { CgbTwoCardIntroBlockPreview } from "../components/blocks/CgbTwoCardIntroBlock"; // eslint-disable-line
import { CgbTwoColInnerBlockPreview } from "../components/blocks/CgbTwoColInnerBlock"; //eslint-disable-line
import { CgbTwoColWrapperBlockPreview } from "../components/blocks/CgbTwoColWrapperBlock"; // eslint-disable-line
import { CgbVideoBannerMessageBlockPreview } from "../components/blocks/CgbVideoBannerMessageBlock"; // eslint-disable-line
import { CgbVideoEmbedBlockPreview } from "../components/blocks/CgbVideoEmbedBlock"; // eslint-disable-line
import { CgbVideoTestimonialBlockPreview } from "../components/blocks/CgbVideoTestimonialBlock"; // eslint-disable-line
import { AcfBrewGalleryBlockPreview } from "../components/blocks/AcfBrewGalleryBlock"; // eslint-disable-line
import { AcfNbsIframeBlockPreview } from "../components/blocks/AcfNbsIframeBlock"; // eslint-disable-line

export const CoreFields = graphql`
  fragment CoreFields on WpBlock {
    name
    ...CoreParagraphBlock
    ...CoreHeadingBlock
    ...CoreImageBlock
    ...CoreColumnBlock
    ...CoreColumnsBlock
    ...CoreButtonsBlock
    ...CoreButtonBlock
    ...CoreGalleryBlock
    ...CoreQuoteBlock
    ...CoreListBlock
    ...CoreListItemBlock
    ...CoreTableBlock
    ...CoreFileBlock
    ...CoreSpacerBlock
    ...GravityformsFormBlock
    ...CgbAccordionBlock
    ...CgbAccordionInnerBlock
    ...CgbBackgroundWrapBlock
    ...CgbBannerCalloutCtaBlock
    ...CgbBannerMessageBlock
    ...CgbBlogBannerBlock
    ...CgbBrandLinkBlock
    ...CgbBrandLinkGridBlock
    ...CgbBrandLinkRowBlock
    ...CgbBreakoutGalleryIntroBlock
    ...CgbBreakoutIntroBlock
    ...CgbBlogPagePostsBlock
    ...CgbBlogPostWrapBlock
    ...CgbCalloutCtaBlock
    ...CgbCardBlock
    ...CgbCardRowBlock
    ...CgbContactBannerBlock
    ...CgbContactCalloutBlock
    ...CgbContactColumnsBlock
    ...CgbFactStackBlock
    ...CgbFactStackInnerBlock
    ...CgbFeaturedIconsBlock
    ...CgbFeaturedIconsInnerBlock
    ...CgbFileListBlock
    ...CgbFileListInnerBlock
    ...CgbFileListInnerSmallBlock
    ...CgbGoodToGoBlock
    ...CgbIconListIntroBlock
    ...CgbIconListInnerBlock
    ...CgbIconListBlock
    ...CgbIconRowBlock
    ...CgbIconRowInnerBlock
    ...CgbImageBreakdownBlock
    ...CgbImageBreakdownInnerBlock
    ...CgbImageContentRowsBlock
    ...CgbImageContentRowsInnerBlock
    ...CgbImageGalleryGridBlock
    ...CgbImageGalleryGridInnerLargeBlock
    ...CgbImageGalleryGridInnerSmallBlock
    ...CgbImageLinkSliderBlock
    ...CgbImageLinkBlock
    ...CgbImageLinkRowBlock
    ...CgbImageSliderBlock
    ...CgbIntroGalleryBlock
    ...CgbIntroGallerySplitBlock
    ...CgbIntroImageSliderBlock
    ...CgbLandingBannerBlock
    ...CgbLandingBannerVideoBlock
    ...CgbLatestPostsBlock
    ...CgbLogoSliderBlock
    ...CgbNextPreviousPostsBlock
    ...CgbOfferGridBlock
    ...CgbOfferGridInnerBlock
    ...CgbPageTitleBlock
    ...CgbPopularProductsBlock
    ...CgbProductsByTagBlock
    ...CgbPullquoteBlock
    ...CgbSeparatorBlock
    ...CgbSocialSharingBlock
    ...AcfSpecifiersProductsBlock
    ...CgbTabContentBlock
    ...CgbTabContentInnerBlock
    ...CgbTeamGridBlock
    ...CgbTeamGridInnerBlock
    ...CgbTestimonialBlock
    ...CgbTimelineBlock
    ...CgbTimelineInnerBlock
    ...CgbThickIntroBlock
    ...CgbThinIntroBlock
    ...CgbTwoCardIntroBlock
    ...CgbTwoColInnerBlock
    ...CgbTwoColWrapperBlock
    ...CgbVideoBannerMessageBlock
    ...CgbVideoEmbedBlock
    ...CgbVideoTestimonialBlock
    ...AcfBrewGalleryBlock
    ...AcfNbsIframeBlock
    innerBlocks {
      name
      ...CoreParagraphBlock
      ...CoreHeadingBlock
      ...CoreImageBlock
      ...CoreColumnBlock
      ...CoreColumnsBlock
      ...CoreButtonsBlock
      ...CoreButtonBlock
      ...CoreGalleryBlock
      ...CoreQuoteBlock
      ...CoreListBlock
      ...CoreListItemBlock
      ...CoreTableBlock
      ...CoreFileBlock
      ...CoreSpacerBlock
      ...GravityformsFormBlock
      ...CgbAccordionBlock
      ...CgbAccordionInnerBlock
      ...CgbBackgroundWrapBlock
      ...CgbBannerCalloutCtaBlock
      ...CgbBannerMessageBlock
      ...CgbBlogBannerBlock
      ...CgbBlogPagePostsBlock
      ...CgbBlogPostWrapBlock
      ...CgbBrandLinkBlock
      ...CgbBrandLinkGridBlock
      ...CgbBrandLinkRowBlock
      ...CgbBreakoutGalleryIntroBlock
      ...CgbBreakoutIntroBlock
      ...CgbCalloutCtaBlock
      ...CgbCardBlock
      ...CgbCardRowBlock
      ...CgbContactBannerBlock
      ...CgbContactCalloutBlock
      ...CgbContactColumnsBlock
      ...CgbFactStackBlock
      ...CgbFactStackInnerBlock
      ...CgbFeaturedIconsBlock
      ...CgbFeaturedIconsInnerBlock
      ...CgbFileListBlock
      ...CgbFileListInnerBlock
      ...CgbFileListInnerSmallBlock
      ...CgbGoodToGoBlock
      ...CgbIconListIntroBlock
      ...CgbIconListInnerBlock
      ...CgbIconListBlock
      ...CgbIconRowBlock
      ...CgbIconRowInnerBlock
      ...CgbImageBreakdownBlock
      ...CgbImageBreakdownInnerBlock
      ...CgbImageContentRowsBlock
      ...CgbImageContentRowsInnerBlock
      ...CgbImageGalleryGridBlock
      ...CgbImageGalleryGridInnerLargeBlock
      ...CgbImageGalleryGridInnerSmallBlock
      ...CgbImageLinkSliderBlock
      ...CgbImageLinkBlock
      ...CgbImageLinkRowBlock
      ...CgbImageSliderBlock
      ...CgbIntroGalleryBlock
      ...CgbIntroGallerySplitBlock
      ...CgbIntroImageSliderBlock
      ...CgbLandingBannerBlock
      ...CgbLandingBannerVideoBlock
      ...CgbLatestPostsBlock
      ...CgbLogoSliderBlock
      ...CgbNextPreviousPostsBlock
      ...CgbOfferGridBlock
      ...CgbOfferGridInnerBlock
      ...CgbPageTitleBlock
      ...CgbPopularProductsBlock
      ...CgbProductsByTagBlock
      ...CgbPullquoteBlock
      ...CgbSeparatorBlock
      ...CgbSocialSharingBlock
      ...AcfSpecifiersProductsBlock
      ...CgbTabContentBlock
      ...CgbTabContentInnerBlock
      ...CgbTeamGridBlock
      ...CgbTeamGridInnerBlock
      ...CgbTestimonialBlock
      ...CgbTimelineBlock
      ...CgbTimelineInnerBlock
      ...CgbThickIntroBlock
      ...CgbThinIntroBlock
      ...CgbTwoCardIntroBlock
      ...CgbTwoColInnerBlock
      ...CgbTwoColWrapperBlock
      ...CgbVideoBannerMessageBlock
      ...CgbVideoEmbedBlock
      ...CgbVideoTestimonialBlock
      ...AcfBrewGalleryBlock
      ...AcfNbsIframeBlock
      innerBlocks {
        name
        ...CoreParagraphBlock
        ...CoreHeadingBlock
        ...CoreImageBlock
        ...CoreColumnBlock
        ...CoreColumnsBlock
        ...CoreButtonsBlock
        ...CoreButtonBlock
        ...CoreGalleryBlock
        ...CoreQuoteBlock
        ...CoreListBlock
        ...CoreListItemBlock
        ...CoreTableBlock
        ...CoreFileBlock
        ...CoreSpacerBlock
        ...GravityformsFormBlock
        ...CgbAccordionBlock
        ...CgbAccordionInnerBlock
        ...CgbBackgroundWrapBlock
        ...CgbBannerCalloutCtaBlock
        ...CgbBannerMessageBlock
        ...CgbBlogBannerBlock
        ...CgbBlogPagePostsBlock
        ...CgbBlogPostWrapBlock
        ...CgbBrandLinkBlock
        ...CgbBrandLinkGridBlock
        ...CgbBrandLinkRowBlock
        ...CgbBreakoutGalleryIntroBlock
        ...CgbBreakoutIntroBlock
        ...CgbCalloutCtaBlock
        ...CgbCardBlock
        ...CgbCardRowBlock
        ...CgbContactBannerBlock
        ...CgbContactCalloutBlock
        ...CgbContactColumnsBlock
        ...CgbFactStackBlock
        ...CgbFactStackInnerBlock
        ...CgbFeaturedIconsBlock
        ...CgbFeaturedIconsInnerBlock
        ...CgbFileListBlock
        ...CgbFileListInnerBlock
        ...CgbFileListInnerSmallBlock
        ...CgbGoodToGoBlock
        ...CgbIconListIntroBlock
        ...CgbIconListInnerBlock
        ...CgbIconListBlock
        ...CgbIconRowBlock
        ...CgbIconRowInnerBlock
        ...CgbImageBreakdownBlock
        ...CgbImageBreakdownInnerBlock
        ...CgbImageContentRowsBlock
        ...CgbImageContentRowsInnerBlock
        ...CgbImageGalleryGridBlock
        ...CgbImageGalleryGridInnerLargeBlock
        ...CgbImageGalleryGridInnerSmallBlock
        ...CgbImageLinkSliderBlock
        ...CgbImageLinkBlock
        ...CgbImageLinkRowBlock
        ...CgbImageSliderBlock
        ...CgbIntroGalleryBlock
        ...CgbIntroGallerySplitBlock
        ...CgbIntroImageSliderBlock
        ...CgbLandingBannerBlock
        ...CgbLandingBannerVideoBlock
        ...CgbLatestPostsBlock
        ...CgbLogoSliderBlock
        ...CgbNextPreviousPostsBlock
        ...CgbOfferGridBlock
        ...CgbOfferGridInnerBlock
        ...CgbPageTitleBlock
        ...CgbPopularProductsBlock
        ...CgbProductsByTagBlock
        ...CgbPullquoteBlock
        ...CgbSeparatorBlock
        ...CgbSocialSharingBlock
        ...AcfSpecifiersProductsBlock
        ...CgbTabContentBlock
        ...CgbTabContentInnerBlock
        ...CgbTeamGridBlock
        ...CgbTeamGridInnerBlock
        ...CgbTestimonialBlock
        ...CgbTimelineBlock
        ...CgbTimelineInnerBlock
        ...CgbThickIntroBlock
        ...CgbThinIntroBlock
        ...CgbTwoCardIntroBlock
        ...CgbTwoColInnerBlock
        ...CgbTwoColWrapperBlock
        ...CgbVideoBannerMessageBlock
        ...CgbVideoEmbedBlock
        ...CgbVideoTestimonialBlock
        ...AcfBrewGalleryBlock
        ...AcfNbsIframeBlock
        innerBlocks {
          name
          ...CoreParagraphBlock
          ...CoreHeadingBlock
          ...CoreImageBlock
          ...CoreColumnBlock
          ...CoreColumnsBlock
          ...CoreButtonsBlock
          ...CoreButtonBlock
          ...CoreGalleryBlock
          ...CoreQuoteBlock
          ...CoreListBlock
          ...CoreListItemBlock
          ...CoreTableBlock
          ...CoreFileBlock
          ...CoreSpacerBlock
          ...GravityformsFormBlock
          ...CgbAccordionBlock
          ...CgbAccordionInnerBlock
          ...CgbBackgroundWrapBlock
          ...CgbBannerCalloutCtaBlock
          ...CgbBannerMessageBlock
          ...CgbBlogBannerBlock
          ...CgbBlogPagePostsBlock
          ...CgbBlogPostWrapBlock
          ...CgbBrandLinkBlock
          ...CgbBrandLinkGridBlock
          ...CgbBrandLinkRowBlock
          ...CgbBreakoutGalleryIntroBlock
          ...CgbBreakoutIntroBlock
          ...CgbCalloutCtaBlock
          ...CgbCardBlock
          ...CgbCardRowBlock
          ...CgbContactBannerBlock
          ...CgbContactCalloutBlock
          ...CgbContactColumnsBlock
          ...CgbFactStackBlock
          ...CgbFactStackInnerBlock
          ...CgbFeaturedIconsBlock
          ...CgbFeaturedIconsInnerBlock
          ...CgbFileListBlock
          ...CgbFileListInnerBlock
          ...CgbFileListInnerSmallBlock
          ...CgbGoodToGoBlock
          ...CgbIconListIntroBlock
          ...CgbIconListInnerBlock
          ...CgbIconListBlock
          ...CgbIconRowBlock
          ...CgbIconRowInnerBlock
          ...CgbImageBreakdownBlock
          ...CgbImageBreakdownInnerBlock
          ...CgbImageContentRowsBlock
          ...CgbImageContentRowsInnerBlock
          ...CgbImageGalleryGridBlock
          ...CgbImageGalleryGridInnerLargeBlock
          ...CgbImageGalleryGridInnerSmallBlock
          ...CgbImageLinkSliderBlock
          ...CgbImageLinkBlock
          ...CgbImageLinkRowBlock
          ...CgbImageSliderBlock
          ...CgbIntroGalleryBlock
          ...CgbIntroGallerySplitBlock
          ...CgbIntroImageSliderBlock
          ...CgbLandingBannerBlock
          ...CgbLandingBannerVideoBlock
          ...CgbLatestPostsBlock
          ...CgbLogoSliderBlock
          ...CgbNextPreviousPostsBlock
          ...CgbOfferGridBlock
          ...CgbOfferGridInnerBlock
          ...CgbPageTitleBlock
          ...CgbPopularProductsBlock
          ...CgbProductsByTagBlock
          ...CgbPullquoteBlock
          ...CgbSeparatorBlock
          ...CgbSocialSharingBlock
          ...AcfSpecifiersProductsBlock
          ...CgbTabContentBlock
          ...CgbTabContentInnerBlock
          ...CgbTeamGridBlock
          ...CgbTeamGridInnerBlock
          ...CgbTestimonialBlock
          ...CgbTimelineBlock
          ...CgbTimelineInnerBlock
          ...CgbThickIntroBlock
          ...CgbThinIntroBlock
          ...CgbTwoCardIntroBlock
          ...CgbTwoColInnerBlock
          ...CgbTwoColWrapperBlock
          ...CgbVideoBannerMessageBlock
          ...CgbVideoEmbedBlock
          ...CgbVideoTestimonialBlock
          ...AcfBrewGalleryBlock
          ...AcfNbsIframeBlock
          innerBlocks {
            name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreImageBlock
            ...CoreColumnBlock
            ...CoreColumnsBlock
            ...CoreButtonsBlock
            ...CoreButtonBlock
            ...CoreGalleryBlock
            ...CoreQuoteBlock
            ...CoreListBlock
            ...CoreListItemBlock
            ...CoreTableBlock
            ...CoreFileBlock
            ...CoreSpacerBlock
            ...GravityformsFormBlock
            ...CgbAccordionBlock
            ...CgbAccordionInnerBlock
            ...CgbBackgroundWrapBlock
            ...CgbBannerCalloutCtaBlock
            ...CgbBannerMessageBlock
            ...CgbBlogBannerBlock
            ...CgbBlogPagePostsBlock
            ...CgbBlogPostWrapBlock
            ...CgbBrandLinkBlock
            ...CgbBrandLinkGridBlock
            ...CgbBrandLinkRowBlock
            ...CgbBreakoutGalleryIntroBlock
            ...CgbBreakoutIntroBlock
            ...CgbCalloutCtaBlock
            ...CgbCardBlock
            ...CgbCardRowBlock
            ...CgbContactBannerBlock
            ...CgbContactCalloutBlock
            ...CgbContactColumnsBlock
            ...CgbFactStackBlock
            ...CgbFactStackInnerBlock
            ...CgbFeaturedIconsBlock
            ...CgbFeaturedIconsInnerBlock
            ...CgbFileListBlock
            ...CgbFileListInnerBlock
            ...CgbFileListInnerSmallBlock
            ...CgbGoodToGoBlock
            ...CgbIconListIntroBlock
            ...CgbIconListInnerBlock
            ...CgbIconListBlock
            ...CgbIconRowBlock
            ...CgbIconRowInnerBlock
            ...CgbImageBreakdownBlock
            ...CgbImageBreakdownInnerBlock
            ...CgbImageContentRowsBlock
            ...CgbImageContentRowsInnerBlock
            ...CgbImageGalleryGridBlock
            ...CgbImageGalleryGridInnerLargeBlock
            ...CgbImageGalleryGridInnerSmallBlock
            ...CgbImageLinkSliderBlock
            ...CgbImageLinkBlock
            ...CgbImageLinkRowBlock
            ...CgbImageSliderBlock
            ...CgbIntroGalleryBlock
            ...CgbIntroGallerySplitBlock
            ...CgbIntroImageSliderBlock
            ...CgbLandingBannerBlock
            ...CgbLandingBannerVideoBlock
            ...CgbLatestPostsBlock
            ...CgbLogoSliderBlock
            ...CgbNextPreviousPostsBlock
            ...CgbOfferGridBlock
            ...CgbOfferGridInnerBlock
            ...CgbPageTitleBlock
            ...CgbPopularProductsBlock
            ...CgbProductsByTagBlock
            ...CgbPullquoteBlock
            ...CgbSeparatorBlock
            ...CgbSocialSharingBlock
            ...AcfSpecifiersProductsBlock
            ...CgbTabContentBlock
            ...CgbTabContentInnerBlock
            ...CgbTeamGridBlock
            ...CgbTeamGridInnerBlock
            ...CgbTestimonialBlock
            ...CgbTimelineBlock
            ...CgbTimelineInnerBlock
            ...CgbThickIntroBlock
            ...CgbThinIntroBlock
            ...CgbTwoCardIntroBlock
            ...CgbTwoColInnerBlock
            ...CgbTwoColWrapperBlock
            ...CgbVideoBannerMessageBlock
            ...CgbVideoEmbedBlock
            ...CgbVideoTestimonialBlock
            ...AcfBrewGalleryBlock
            ...AcfNbsIframeBlock
          }
        }
      }
    }
  }
`;

export const PreviewBlocks = `
  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreColumnBlockPreview}
  ${CoreColumnsBlockPreview}
  ${CoreButtonsBlockPreview}
  ${CoreButtonBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreQuoteBlockPreview}
  ${CoreListBlockPreview}
  ${CoreListItemBlockPreview}
  ${CoreTableBlockPreview}
  ${CoreFileBlockPreview}
  ${CoreSpacerBlockPreview}
  ${GravityformsFormBlockPreview}
  ${CgbAccordionBlockPreview}
  ${CgbAccordionInnerBlockPreview}
  ${CgbBackgroundWrapBlockPreview}
  ${CgbBannerCalloutCtaBlockPreview}
  ${CgbBannerMessageBlockPreview}
  ${CgbBlogBannerBlockPreview}
  ${CgbBlogPagePostsBlockPreview}
  ${CgbBlogPostWrapBlockPreview}
  ${CgbBrandLinkBlockPreview}
  ${CgbBrandLinkGridBlockPreview}
  ${CgbBrandLinkRowBlockPreview}
  ${CgbBreakoutGalleryIntroBlockPreview}
  ${CgbBreakoutIntroBlockPreview}
  ${CgbCalloutCtaBlockPreview}
  ${CgbCardBlockPreview}
  ${CgbCardRowBlockPreview}
  ${CgbContactBannerBlockPreview}
  ${CgbContactCalloutBlockPreview}
  ${CgbContactColumnsBlockPreview}
  ${CgbFactStackBlockPreview}
  ${CgbFactStackInnerBlockPreview}
  ${CgbFeaturedIconsBlockPreview}
  ${CgbFeaturedIconsInnerBlockPreview}
  ${CgbFileListBlockPreview}
  ${CgbFileListInnerBlockPreview}
  ${CgbFileListInnerSmallBlockPreview}
  ${CgbGoodToGoBlockPreview}
  ${CgbIconListIntroBlockPreview}
  ${CgbIconListInnerBlockPreview}
  ${CgbIconListBlockPreview}
  ${CgbIconRowBlockPreview}
  ${CgbIconRowInnerBlockPreview}
  ${CgbImageBreakdownBlockPreview}
  ${CgbImageBreakdownInnerBlockPreview}
  ${CgbImageContentRowsBlockPreview}
  ${CgbImageContentRowsInnerBlockPreview}
  ${CgbImageGalleryGridBlockPreview}
  ${CgbImageGalleryGridInnerLargeBlockPreview}
  ${CgbImageGalleryGridInnerSmallBlockPreview}
  ${CgbImageLinkSliderBlockPreview}
  ${CgbImageLinkBlockPreview}
  ${CgbImageLinkRowBlockPreview}
  ${CgbImageSliderBlockPreview}
  ${CgbIntroGalleryBlockPreview}
  ${CgbIntroGallerySplitBlockPreview}
  ${CgbIntroImageSliderBlockPreview}
  ${CgbLandingBannerBlockPreview}
  ${CgbLandingBannerVideoBlockPreview}
  ${CgbLatestPostsBlockPreview}
  ${CgbLogoSliderBlockPreview}
  ${CgbNextPreviousPostsBlockPreview}
  ${CgbOfferGridBlockPreview}
  ${CgbOfferGridInnerBlockPreview}
  ${CgbPageTitleBlockPreview}
  ${CgbPopularProductsBlockPreview}
  ${CgbProductsByTagBlockPreview}
  ${CgbPullquoteBlockPreview}
  ${CgbSeparatorBlockPreview}
  ${CgbSocialSharingBlockPreview}
  ${AcfSpecifiersProductsBlockPreview}
  ${CgbTabContentBlockPreview}
  ${CgbTabContentInnerBlockPreview}
  ${CgbTeamGridBlockPreview}
  ${CgbTeamGridInnerBlockPreview}
  ${CgbTestimonialBlockPreview}
  ${CgbTimelineBlockPreview}
  ${CgbTimelineInnerBlockPreview}
  ${CgbThickIntroBlockPreview}
  ${CgbThinIntroBlockPreview}
  ${CgbTwoCardIntroBlockPreview}
  ${CgbTwoColInnerBlockPreview}
  ${CgbTwoColWrapperBlockPreview}
  ${CgbVideoBannerMessageBlockPreview}
  ${CgbVideoEmbedBlockPreview}
  ${CgbVideoTestimonialBlockPreview}
  ${AcfBrewGalleryBlockPreview}
  ${AcfNbsIframeBlockPreview}
  `;
