import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Link from "../Link";
import styles from "./styles";

import ShareIcon from "@material-ui/icons/Share";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles((theme) => styles(theme));

export default function SocialShare(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    query urlGrabSS {
      wp {
        locationData {
          LocationData {
            url
          }
        }
      }
    }
  `);

  // console.log(props);

  return (
    <div className={`SocialShare ${classes.socialShare}`} open={open}>
      <button onClick={handleOpen} onKeyDown={handleOpen}>
        Share <ShareIcon />
      </button>
      <div className="buttons">
        <Link
          className="email"
          to={
            "mailto:?subject=" +
            props.title +
            "&body=" +
            data.wp.locationData.LocationData.url +
            "/plumbing" +
            props.path
          }
        >
          <EmailIcon /> Share
        </Link>
        <Link
          className="whatsapp"
          to={
            "whatsapp://send?text=" +
            data.wp.locationData.LocationData.url +
            "/plumbing" +
            props.path
          }
        >
          <WhatsAppIcon /> Share
        </Link>
        <Link
          className="facebook"
          to={
            "https://www.facebook.com/sharer/sharer.php?u=" +
            data.wp.locationData.LocationData.url +
            "/plumbing" +
            props.path
          }
        >
          <FacebookIcon /> Share
        </Link>
        <Link
          className="twitter"
          to={
            "https://twitter.com/intent/tweet?text=" +
            props.title +
            "&url=" +
            data.wp.locationData.LocationData.url +
            "/plumbing" +
            props.path
          }
        >
          <TwitterIcon /> Share
        </Link>
      </div>
    </div>
  );
}
