import React from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { transformLink } from "../../../helpers";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Img from "gatsby-image";
import Link from "../../Link";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CoreImageBlock = (props) => {
  const classes = useStyles();
  // console.log(props);
  // return ReactHtmlParser(props.originalContent.replace(/1024px/g, "1440px"), {transform : transformLink});

  if (!props.attributes.url) return null;
  let image = null;

  // Use the gatsby image if available
  if (props.attributes.localFile) {
    if (props.attributes.localFile.childImageSharp) {
      image = (
        <Img
          fluid={props.attributes.localFile.childImageSharp.fluid}
          alt={props.attributes.alt ? props.attributes.alt : ""}
          className={`img-align-${props.attributes.align} ${
            props.attributes.className ? props.attributes.className : ""
          }`}
          width={props.attributes.width ? props.attributes.width : ""}
          height={props.attributes.height ? props.attributes.height : ""}
        />
      );
    } else {
      // Gifs do not have a childImageSharp
      image = (
        <img
          src={props.attributes.localFile.publicURL}
          alt={props.attributes.alt ? props.attributes.alt : ""}
          className={`img-align-${props.attributes.align} ${
            props.attributes.className ? props.attributes.className : ""
          }`}
          width={props.attributes.width ? props.attributes.width : ""}
          height={props.attributes.height ? props.attributes.height : ""}
        />
      );
    }
  } else {
    // console.log(props.attributes.height);
    // This is a preview image
    // let per = props.attributes.height / props.attributes.width;
    // console.log(per);
    image = (
      <div className="gatsby-image-wrapper">
        <img
          src={`${
            props.attributes.url.charAt(0) === "/"
              ? "https://tyde2104.thomasdudley.co.uk"
              : ""
          }${props.attributes.url}`}
          alt={props.attributes.alt ? props.attributes.alt : ""}
          className={`img-align-${props.attributes.align} ${
            props.attributes.className ? props.attributes.className : ""
          }`}
          style={{
            // position: "absolute",
            // top: 0,
            // left: 0,
            width: "100%",
            // height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    );
  }

  if (props.attributes.caption) {
    image = (
      <figure
        className={`wp-block-image image-with-caption ${props.attributes.className}`}
      >
        {image}
        <Typography
          component="p"
          variant="body1"
          className={`caption ${classes.imageCaption} ${classes.imageCaptionTheme}`}
          paragraph={true}
        >
          {ReactHtmlParser(props.attributes.caption, {
            transform: transformLink,
          })}
        </Typography>
      </figure>
    );
  } else {
    image = (
      <figure className={`wp-block-image ${props.attributes.className}`}>
        {image}
      </figure>
    );
  }

  if (props.attributes.href) {
    return (
      <Link
        to={props.attributes.href}
        title={props.attributes.title ? props.attributes.title : ""}
        className={props.attributes.linkClass ? props.attributes.linkClass : ""}
      >
        {image}
      </Link>
    );
  } else {
    return image;
  }
};

CoreImageBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CoreImageBlockFragment = graphql`
  fragment CoreImageBlock on WpBlock {
    ... on WpCoreImageBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCoreImageBlockAttributes {
          align
          alt
          caption
          className
          href
          id
          linkClass
          linkDestination
          linkTarget
          rel
          sizeSlug
          title
          url
          width
          height
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        ... on WpCoreImageBlockDeprecatedV7Attributes {
          align
          alt
          caption
          className
          href
          id
          linkClass
          linkDestination
          linkTarget
          rel
          sizeSlug
          title
          url
          width
          height
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export const CoreImageBlockPreview = `
  ... on CoreImageBlock {
    name
    originalContent
    attributes {
      __typename
      ... on CoreImageBlockAttributes {
        align
        alt
        caption
        className
        href
        id
        linkClass
        linkDestination
        linkTarget
        rel
        sizeSlug
        title
        url
        width
        height
      }
      ... on CoreImageBlockDeprecatedV7Attributes {
        align
        alt
        caption
        className
        href
        id
        linkClass
        linkDestination
        linkTarget
        rel
        sizeSlug
        title
        url
        width
        height
      }
    }
  }`;
