export default theme => ({
    backgroundWrap: {
        position: 'relative',
        padding: '24px 0',
        [theme.breakpoints.up('md')]: {
            padding: '32px 0',
        },
        '&::before': {
            backgroundColor: theme.palette.primary.main + '14',
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            width: '100vw',
            transform: 'translateX(-50%)',
            zIndex: -1,
        }
    },
})